@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Barlow+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap");
@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";

@font-face {
  font-family: "Bison";
  src: url(https://assets.dailyprincetonian.com/common/fonts/Bison/Bison-Regular.ttf);
}

@font-face {
  font-family: "Bison";
  src: url(https://assets.dailyprincetonian.com/common/fonts/Bison/Bison-RegularItalic.ttf);
  font-style: italic;
}

@font-face {
  font-family: "Bison";
  src: url(https://assets.dailyprincetonian.com/common/fonts/Bison/Bison-Light.ttf);
  font-weight: 100;
}

@font-face {
  font-family: "Bison";
  src: url(https://assets.dailyprincetonian.com/common/fonts/Bison/Bison-LightItalic.ttf);
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: "Bison";
  src: url(https://assets.dailyprincetonian.com/common/fonts/Bison/Bison-DemiBold.ttf);
  font-weight: 600;
}

@font-face {
  font-family: "Bison";
  src: url(https://assets.dailyprincetonian.com/common/fonts/Bison/Bison-DemiBoldItalic.ttf);
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "Bison";
  src: url(https://assets.dailyprincetonian.com/common/fonts/Bison/Bison-Bold.ttf);
  font-weight: 700;
}

@font-face {
  font-family: "Bison";
  src: url(https://assets.dailyprincetonian.com/common/fonts/Bison/Bison-BoldItalic.ttf);
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "Bison Outline";
  src: url(https://assets.dailyprincetonian.com/common/fonts/Bison/Bison-ThinOutline.ttf);
}

@font-face {
  font-family: "Bison Outline";
  src: url(https://assets.dailyprincetonian.com/common/fonts/Bison/Bison-ThinOutlineItalic.ttf);
  font-style: italic;
}

@font-face {
  font-family: "Bison Outline";
  src: url(https://assets.dailyprincetonian.com/common/fonts/Bison/Bison-ThickOutline.ttf);
  font-weight: 700;
}

@font-face {
  font-family: "Bison Outline";
  src: url(https://assets.dailyprincetonian.com/common/fonts/Bison/Bison-ThickOutlineItalic.ttf);
  font-weight: 700;
  font-style: italic;
}
